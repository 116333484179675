<template>
  <header class="header">

    <div class="header__top">

      <div class="ticker-and-language-wrapper">
        <div class="tickerwrapper wrapper">
          <ul class='list'>

            <li class='listitem' v-for="(message, index) in translatedMessages" :key="index">
              <span>{{ message.title }}</span>
            </li>


          </ul>
        </div>
        <div class="language-selector">
          <select v-model="currentLanguage" @change="changeLanguage($event)">
            <option value="en">EN</option>
            <option value="es">ES</option>
          </select>
        </div>
      </div>
    </div>

    <div class="header__bottom" id="header__bottom">
      <!-- Language Selector Dropdown -->

      <div class="header__container wrapper">

        <router-link style="display:flex;" to="/">
          <img class="header__logo" src="@/assets/img/logo.png" alt="Logo">
        </router-link>

        <button class="header__mobileButton" @click="openCloseMenu"><i class="ri-menu-line"></i></button>
        <div style="font-weight: bold; font-size: 1.7em;">
          <a :href="'tel:' + $t('nav.contact').replace(/-/g, '')"
            :target="$store.state.isMobile ? '_blank' : '_self'">{{ $t('nav.contact') }}</a>

        </div>
        <nav class="header__nav" id="header__menu">
          <router-link class="header__navLink" to="/">{{ $t('nav.home') }}</router-link>
          <a class="header__navLink" href="#about-us">{{ $t('nav.aboutUs') }}</a>
          <a class="header__navLink" href="#why-choose-us">{{ $t('nav.whyChooseUs') }}</a>
          <a class="header__navLink" href="#coverage-area">{{ $t('nav.coverageArea') }}</a>
          <a class="header__navLink" href="#faqs">{{ $t('nav.faqs') }}</a>
        </nav>


      </div>

    </div>

  </header>
</template>

<script>
import $ from 'jquery';
import { gsap } from 'gsap';

export default {
  data() {
    return {
      currentLanguage: this.$i18n.locale, // Set initial value to i18n's current locale
    };
  },
  name: 'Header',
  computed: {
    translatedMessages() {
      return [
        {
          title: this.$t('headerMessages.insured')
        },
        {
          title: this.$t('headerMessages.discount')
        },
        {
          title: this.$t('headerMessages.paymentOnDelivery')
        },
        {
          title: this.$t('headerMessages.bookNow')
        },
        {
          title: this.$t('headerMessages.trackShipping')
        },
      ];
    }
  },
  methods: {
    changeLanguage(event) {
      const newLang = event.target.value;
      this.$i18n.locale = newLang;
      this.currentLanguage = newLang; // Update the data property
    },
    openCloseMenu() {
      const headerNav = document.querySelectorAll('.header__nav')[0];
      headerNav.classList.toggle('header__nav--show');
    },

    // Inside your methods
    runMessages() {
      var $tickerWrapper = $(".tickerwrapper");
      var $list = $tickerWrapper.find("ul.list");
      var $clonedList = $list.clone();
      var listWidth = 500;

      $list.find("li").each(function (i) {
        listWidth += $(this, i).outerWidth(true);
      });

      var endPos = $tickerWrapper.width() - listWidth;

      $list.add($clonedList).css({
        "width": listWidth + "px"
      });

      $clonedList.addClass("cloned").appendTo($tickerWrapper);

      var timeline = gsap.timeline({ repeat: -1, paused: true });
      var time = 40;

      timeline
        .fromTo($list, { x: 0 }, { x: -listWidth, duration: time, ease: "none" })
        .fromTo($clonedList, { x: listWidth }, { x: 0, duration: time, ease: "none" }, 0)
        .set($list, { x: listWidth })
        .to($clonedList, { x: -listWidth, duration: time, ease: "none" }, time)
        .to($list, { x: 0, duration: time, ease: "none" }, time);

      $tickerWrapper.on("mouseenter", function () {
        timeline.pause();
      }).on("mouseleave", function () {
        timeline.play();
      });
    }

  },
  mounted() {
    this.currentLanguage = this.$i18n.locale;
    this.runMessages();

    window.onscroll = function () { myFunction() };

    var header = document.getElementById("header__bottom");
    var headerMenu = document.getElementById("header__menu");
    var sticky = header.offsetTop;

    function myFunction() {
      if (window.pageYOffset > 100) {
        header.classList.add("hfe-sticky");
        headerMenu.classList.add("hfe-sticky");
      } else {
        setTimeout(function () {
          header.classList.remove("hfe-sticky");
          headerMenu.classList.remove("hfe-sticky");
        }, 100);
      }
    }

  }
}
</script>

<style scoped>
.header__top {
  background-color: var(--SecondaryColor);
  min-height: 40px;
  width: 100%;
}

.header__bottom {
  background-color: var(--PrimaryColor);
  width: 100%;
}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  margin-left: -10px;
}

.header__nav {
  display: flex;
  align-items: center;
}

.header__navLink {
  font-weight: 500;
  margin-left: 40px;
}

.header__navLink--button {
  background-color: var(--SecondaryColor);
  color: var(--ComplementaryColor03);
  font-weight: 600;
  padding: 10px 25px;
  border-radius: 5px;
}

.header__mobileButton {
  display: none;
  width: 45px;
  height: 45px;
  font-size: 2rem;
}

.header__nav--show {
  display: flex !important;
}

#header__bottom.hfe-sticky {
  position: fixed;
  left: 0;
  width: 100%;
  animation: slide-down 0.8s;
  margin: -40px 0 0 0;
  z-index: 999;
  box-shadow: 0 0 3px rgb(0 0 0 / 50%);
}

#header__menu.hfe-sticky {
  top: 70px !important;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 0.9;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 768px) {

  .header__mobileButton {
    display: block;
  }

  .header__nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 110px;
    left: 0;
    z-index: 20;
    width: 100%;
    height: auto;
    align-items: flex-start;
    background-color: var(--PrimaryColor);
  }

  .header__logo {
    max-width: 250px;
  }

  .header__navLink--button {
    display: none;
  }

  .header__navLink {
    margin-left: 0;
    padding: 10px 20px;
    width: 100%;
    border-top: 1px solid #e6d218;
  }

}

@media only screen and (max-width: 1150px) {

  .header__mobileButton {
    display: block;
  }

  .header__nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 110px;
    left: 0;
    z-index: 20;
    width: 100%;
    height: auto;
    align-items: flex-start;
    background-color: var(--PrimaryColor);
  }

  .header__logo {
    max-width: 250px;
  }

  .header__navLink--button {
    display: none;
  }

  .header__navLink {
    margin-left: 0;
    padding: 10px 20px;
    width: 100%;
    border-top: 1px solid #e6d218;
  }

}

.tickerwrapper {
  position: relative;
  top: 0px;
  left: 0%;
  width: 99.9%;
  overflow: hidden;
  cursor: pointer;
}


ul.list {
  position: relative;
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.list.cloned {
  position: absolute;
  top: 0px;
  left: 0px;
}

ul.list li {
  float: left;
  padding-left: 100px;
  color: var(--ComplementaryColor03);
  font-weight: 300;
  font-size: 1.3rem;
  padding-top: 10px;
}

ul.list li:hover {
  color: #FCE519;
}

@media only screen and (max-width: 767px) {

  ul.list li {
    font-size: 12px;
  }

}

/* Updated Style for the dropdown */
.language-selector select {
  padding: 5px 10px;
  /* Adjust padding as needed */
  border-radius: 4px;
  border: none;
  background-color: var(--SecondaryColor);
  /* Match the background color */
  color: var(--ComplementaryColor03);
  /* Match the text color */
  font-weight: bold;
}

/* Updated Positioning */
.header__container.wrapper {
  display: flex;
  justify-content: space-between;
}

.language-selector {
  align-self: center;
  /* Change from flex-end to center */
  margin-left: 10px;
  /* Add some left margin */
}

.ticker-and-language-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>