<template>
    <div class="subtle-modal">
      <transition name="slide">
        <div v-if="currentMessage !== ''" :key="currentMessage" class="message">
          {{ currentMessage }}
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SmallModal',
    props: ['currentMessage']
  };
  </script>
  
  <style scoped>
  .subtle-modal {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 99999;
  }
  
  .message {
    background-color: rgb(255, 255, 255);
    padding: 10px 20px;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.215);
    opacity: 1; /* Ensure it starts fully visible */
  }
  
  .slide-enter-active,
  .slide-leave-active {
    transition: opacity 0.5s ease;
  }
  
  .slide-enter,
  .slide-leave-to {
    opacity: 0;
  }
  </style>
  