
<template>

    <section class="banner wrapper" id="banner">
        <div class="banner__logo">
            <img class="banner__img" src="@/assets/img/banner/garlasllc-banner-tittle.svg" alt="Garlas Logo">
        </div>

        <div class="banner__container">
            <div class="banner__container__left">
                <div class="banner__card">
                    <h1 class="banner__heading">{{ $t('banner.title') }}
                    </h1>

                    <div class="banner__card__content">
                        <p class="banner__card__description">
                            {{ $t('banner.description') }}
                        </p>
                        <p class="banner__card__word">{{ $t('banner.word') }}</p>
                    </div>
                </div>
            </div>
            <div class="banner__container__right">
                <div class="banner__img__container">
                    <img width="18%" src="@/assets/img/banner/logo_nomanejes.svg" alt="No Manejes Logo">
                    <img width="20%" src="@/assets/img/banner/logo_puertoricocartransfer.svg"
                        alt="Puerto Rico Car Transfer Logo">
                    <img width="14%" src="@/assets/img/banner/logo_carrosacentroamerica.svg"
                        alt="Carros A Centro America Logo">
                    <img width="12%" src="@/assets/img/banner/logo_prautotransport.svg" alt="Pr Auto Transport Logo">
                    <img width="10%" src="@/assets/img/banner/logo_carmoversus.svg" alt="Car Movers Us Logo">
                </div>
                <div class="banner__img__container">
                    <img width="34%" src="@/assets/img/banner/logo_shipshippersusa.svg" alt="Ship Shippers Usa Logo">
                    <img width="22%" src="@/assets/img/banner/logo_ratesautotransport.svg"
                        alt=" Rates Auto Transport Logo">
                    <img width="12%" src="@/assets/img/banner/logo_tucarroporusa.svg" alt="Tu Carro Por USA Logo">
                    <img width="20%" src="@/assets/img/banner/logo_tcpamerica.svg" alt="Tcp America Logo">
                </div>
                <!-- Ally Logos -->
                <div class="banner__img__container">
                    <img width="32%" src="@/assets/img/banner/logo_ally_centraldispatch.svg"
                        alt="Central Dispatch Logo">
                    <img width="20%" src="@/assets/img/banner/logo_ally_seaboardmarine.svg" alt="Seaboard Marine Logo">
                    <img width="26%" src="@/assets/img/banner/logo_ally_trailerbridge.svg" alt="Trailer Bridge Logo">
                </div>

                <div class="banner__img__container">
                    <img width="26%" src="@/assets/img/banner/logo_ally_crowley.svg" alt="Crowley Logo">
                    <img width="16%" src="@/assets/img/banner/logo_ally_interport.svg" alt="Interport Logo">
                    <img width="20%" src="@/assets/img/banner/logo_ally_cmacgm.svg" alt="CMACGM Logo">
                </div>

                <!-- payments -->
                <div class="banner__img__container">
                    <div class="banner__img__payments">
                        <img height="28px" src="@/assets/img/banner/logo_payment_visa.svg" alt="Visa Logo">
                        <img height="38px" src="@/assets/img/banner/logo_payment_mastercard.svg" alt="Mastercard Logo">
                        <img height="28px" src="@/assets/img/banner/logo_payment_americanexpress.svg"
                            alt="American Express Logo">
                    </div>
                    <p class="banner__payments__description">{{ $t('banner.payment_span') }}</p>
                </div>
            </div>
            <div class="banner__container__slider">
                <div class="logo__slider">
                    <div class="logo__slider__track">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_nomanejes.svg"
                            alt="No Manejes Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_puertoricocartransfer.svg"
                            alt="Puerto Rico Car Transfer Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_carrosacentroamerica.svg"
                            alt="Carros A Centro America Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_prautotransport.svg"
                            alt="Pr Auto Transport Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_carmoversus.svg"
                            alt="Car Movers Us Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_shipshippersusa.svg"
                            alt="Ship Shippers Usa Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_ratesautotransport.svg"
                            alt=" Rates Auto Transport Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_tucarroporusa.svg"
                            alt="Tu Carro Por USA Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_tcpamerica.svg"
                            alt="Tcp America Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_nomanejes.svg"
                            alt="No Manejes Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_puertoricocartransfer.svg"
                            alt="Puerto Rico Car Transfer Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_carrosacentroamerica.svg"
                            alt="Carros A Centro America Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_prautotransport.svg"
                            alt="Pr Auto Transport Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_carmoversus.svg"
                            alt="Car Movers Us Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_shipshippersusa.svg"
                            alt="Ship Shippers Usa Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_ratesautotransport.svg"
                            alt=" Rates Auto Transport Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_tucarroporusa.svg"
                            alt="Tu Carro Por USA Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_tcpamerica.svg"
                            alt="Tcp America Logo">
                    </div>
                </div>
                <div class="logo__slider">
                    <div class="logo__slider__track">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_ally_centraldispatch.svg"
                            alt="Central Dispatch Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_ally_seaboardmarine.svg"
                            alt="Seaboard Marine Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_ally_trailerbridge.svg"
                            alt="Trailer Bridge Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_ally_crowley.svg"
                            alt="Crowley Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_ally_interport.svg"
                            alt="Interport Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_ally_cmacgm.svg" alt="CMACGM Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_ally_centraldispatch.svg"
                            alt="Central Dispatch Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_ally_seaboardmarine.svg"
                            alt="Seaboard Marine Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_ally_trailerbridge.svg"
                            alt="Trailer Bridge Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_ally_crowley.svg"
                            alt="Crowley Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_ally_interport.svg"
                            alt="Interport Logo">
                        <img class="logo__slider__img" src="@/assets/img/banner/logo_ally_cmacgm.svg" alt="CMACGM Logo">
                    </div>
                </div>
                <div class="logo__slider__payment">
                    <img height="28px" src="@/assets/img/banner/logo_payment_visa.svg" alt="Visa Logo">
                    <img height="38px" src="@/assets/img/banner/logo_payment_mastercard.svg" alt="Mastercard Logo">
                    <img height="28px" src="@/assets/img/banner/logo_payment_americanexpress.svg"
                        alt="American Express Logo">
                </div>
                <div class="logo__slider__description">
                    <p>{{ $t('banner.payment_span') }}</p>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default { name: "Banner" }
</script>

<style>
.banner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
    padding-block: 6rem !important;
}

.banner__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 6rem;
}

.banner__container__left,
.banner__container__right {
    display: flex;
    flex-direction: column;
    width: 50%;
    min-height: 100%;
}

.banner__container__right {
    align-content: center;
    gap: 2rem;
}

.banner__heading {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 600;
    color: var(--SecondaryColor);
}

.banner__card {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.banner__card__content {
    height: 100%;
    background-color: var(--PrimaryColor);
    padding: 2rem;
    border-radius: 25px;
    color: var(--SecondaryColor);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.banner__card__description {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.banner__card__word {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 600;
    place-self: end;
}

.banner__img__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.banner__img__payments {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.banner__payments__description {
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
    color: var(--SecondaryColor);
}

@media only screen and (min-width: 770px) {
    .banner__container__slider {
        display: none !important;
    }
}


@media only screen and (max-width: 770px) {
    .banner__container {
        flex-direction: column;
        gap: 2rem;
    }

    .banner__container__left {
        width: 100%;
    }

    .banner__container__right {
        display: none;
    }
}

.banner__container__slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    width: 100%;
    padding-block: 2rem;
}

.logo__slider {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin-block: 1rem;
    mask-image: linear-gradient(to right, transparent 0, white 10px, white calc(100% - 10px), transparent 100%);
    width: 100%;
}

.logo__slider__track {
    animation: slide-left 40s linear infinite;
    white-space: nowrap;
    display: inline-flex;
}

.logo__slider:hover .logo__slider__track {
    animation-play-state: paused;
}

@keyframes slide-left {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-50%);
    }
}

.logo__slider__img {
    margin-inline: 1rem;
    height: 35px;
    flex-shrink: 0;
}

.logo__slider__payment {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 2.5rem;
}

.logo__slider__description {
    text-align: center;
    font-size: 2.6rem;
    line-height: 3rem;
    letter-spacing: 5%;
    font-weight: 500;
    color: var(--SecondaryColor);
}
</style>