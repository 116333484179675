<template>
  <div>

    <section class="about" id="about-us">

      <div class="about__container wrapper">

        <h2 class="about__title">{{ $t('Rates Auto Transport') }}</h2>
        <p class="about__description">{{ $t('description_rates') }}</p>

        <div class="video-container">
          <video :src="$t('assets.route_video_home')" autoplay loop muted></video>
        </div>

        <div class="about__services">

          <div class="services__container" v-for="(service, index) in services" :key="index">
            <div class="services__box">
              <img class="services__img" :src="require(`@/assets/img/srv/${service.icon_color}-icon.png`)" alt="Icon">
              <img class="services__line" :src="require(`@/assets/img/srv/${service.line_color}-line.png`)" alt="Line">
            </div>
            <div class="services__box">
              <p class="services__title">{{ $t(`services.${index}.title`) }}</p>
              <p class="services__description" v-html="$t(`services.${index}.description`)"></p>
            </div>
          </div>


        </div>

      </div>
    </section>

    <section class="whyUs" id="why-choose-us">
      <div class="whyUs__container wrapper">
        <h2 class="whyUs__title">{{ $t('whyUsTitle') }}</h2>
        <div class="whyUs__reasons">

          <div class="reasons__box reasons__box--mod01">
            <img class="reasons__img" src="@/assets/img/whyus/whyus-1.svg" alt="Icon">
            <h3 class="reasons__title">{{ $t('reason1.title') }}</h3>
            <p class="reasons__description">{{ $t('reason1.description') }}</p>
          </div>

          <div class="reasons__box">
            <img class="reasons__img" src="@/assets/img/whyus/whyus-2.svg" alt="Icon">
            <h3 class="reasons__title">{{ $t('reason2.title') }}</h3>
            <p class="reasons__description">{{ $t('reason2.description') }}</p>
          </div>

          <div class="reasons__box reasons__box--mod02">
            <img class="reasons__img" src="@/assets/img/whyus/whyus-3.svg" alt="Icon">
            <h3 class="reasons__title">{{ $t('reason3.title') }}</h3>
            <p class="reasons__description">{{ $t('reason3.description') }}</p>
          </div>

        </div>
      </div>
    </section>

    <section class="destination" id="coverage-area">
      <div class="destination__container wrapper">

        <h2 class="destination__title">{{ $t('destination.title') }}</h2>
        <p class="destination__subtitle">{{ $t('destination.subtitle') }}</p>

        <div class="destination__map">
          <!-- <div id="usaMap"></div> -->
          <Map />
          <p class="map__subtitle">{{ $t('destination.mapSubtitle') }}</p>
        </div>

        <div class="destination__testimonials" id="destination__testimonials1">
          <div class="testimonials__box testimonials__box--mod1">
            <img class="testimonials__img" src="@/assets/img/users/simon-r.jpg" alt="Profile Picture">
          </div>
          <div class="testimonials__box testimonials__box--mod2">
            <p class="testimonials__name">{{ $t('testimonials_0_name') }}</p>
            <p class="testimonials__description">{{ $t('testimonials_0_description')}}</p>
          </div>
        </div>

        <div class="destination__testimonials" id="destination__testimonials2">
          <div class="testimonials__box testimonials__box--mod1">
            <img class="testimonials__img" src="@/assets/img/users/glenda-f.jpg" alt="Profile Picture">
          </div>
          <div class="testimonials__box testimonials__box--mod2">
            <p class="testimonials__name">{{ $t('testimonials_1_name') }}</p>
            <p class="testimonials__description">{{ $t('testimonials_1_description')}}</p>
          </div>
        </div>

        <div class="destination__testimonials" id="destination__testimonials3">
          <div class="testimonials__box testimonials__box--mod1">
            <img class="testimonials__img" src="@/assets/img/users/james-m.jpg" alt="Profile Picture">
          </div>
          <div class="testimonials__box testimonials__box--mod2">
            <p class="testimonials__name">{{ $t('testimonials_2_name') }}</p>
            <p class="testimonials__description">{{ $t('testimonials_2_description')}}</p>
          </div>
        </div>

        <div class="destination__testimonials" id="destination__testimonials4">
          <div class="testimonials__box testimonials__box--mod1">
            <img class="testimonials__img" src="@/assets/img/users/janice-l.jpg" alt="Profile Picture">
          </div>
          <div class="testimonials__box testimonials__box--mod2">
            <p class="testimonials__name">{{ $t('testimonials_3_name') }}</p>
            <p class="testimonials__description">{{ $t('testimonials_3_description')}}</p>
          </div>
        </div>

      </div>
    </section>

    <section class="faqs" id="faqs">
      <div class="faqs__container wrapper">

        <h2 class="faqs__title">{{ $t('faqsTitle') }}</h2>

        <div class="faqs__questions">
          <div class="questions__box">

            <div class="questions__subBox">
              <h3 class="questions__title">{{ $t('faqs_q1_title') }}</h3>
              <p class="questions__description">{{ $t('faqs_q1_description') }}</p>
            </div>

            <div class="questions__subBox">
              <h3 class="questions__title">{{ $t('faqs_q2_title') }}</h3>
              <p class="questions__description">{{ $t('faqs_q2_description') }}</p>
            </div>

            <div class="questions__accordion">
              <div class="accordion__title">{{ $t('faqs_accordion_q1_title') }}</div>
              <div class="accordion__description">{{ $t('faqs_accordion_q1_description') }}</div>
            </div>

            <div class="questions__accordion">
              <div class="accordion__title">{{ $t('faqs_accordion_q2_title') }}</div>
              <div class="accordion__description">{{ $t('faqs_accordion_q2_description') }}</div>
            </div>

            <div class="questions__accordion">
              <div class="accordion__title">{{ $t('faqs_accordion_q3_title') }}</div>
              <div class="accordion__description">{{ $t('faqs_accordion_q3_description') }}</div>
            </div>

            <div class="questions__accordion">
              <div class="accordion__title">{{ $t('faqs_accordion_q4_title') }}</div>
              <div class="accordion__description">{{ $t('faqs_accordion_q4_description') }}</div>
            </div>

            <div class="questions__accordion">
              <div class="accordion__title">{{ $t('faqs_accordion_q5_title') }}</div>
              <div class="accordion__description">{{ $t('faqs_accordion_q5_description') }}</div>
            </div>

            <div class="questions__accordion">
              <div class="accordion__title">{{ $t('faqs_accordion_q6_title') }}</div>
              <div class="accordion__description">{{ $t('faqs_accordion_q6_description') }}</div>
            </div>

            <p class="questions__ready">{{ $t('faqs_ready') }} <span class="questions__ready--link">{{
                $t('faqs_ready_link') }}</span></p>

          </div>
          <div class="questions__box">

            <div class="questions__subBox">
              <h3 class="questions__title">{{ $t('faqs_subBox_q1_title') }}</h3>
              <p class="questions__description" v-html="$t('faqs_subBox_q1_description')"></p>
            </div>

            <div class="questions__accordion">
              <div class="accordion__title">{{ $t('faqs_accordion_q7_title') }}</div>
              <div class="accordion__description" v-html="$t('faqs_accordion_q7_description')"></div>
            </div>

            <div class="questions__accordion">
              <div class="accordion__title">{{ $t('faqs_accordion_q8_title') }}</div>
              <div class="accordion__description">{{ $t('faqs_accordion_q8_description') }}</div>
            </div>

            <div class="questions__accordion">
              <div class="accordion__title">{{ $t('faqs_accordion_q9_title') }}</div>
              <div class="accordion__description">{{ $t('faqs_accordion_q9_description') }}</div>
            </div>

            <div class="questions__accordion">
              <div class="accordion__title">{{ $t('faqs_accordion_q10_title') }}</div>
              <div class="accordion__description">{{ $t('faqs_accordion_q10_description') }}</div>
            </div>

            <div class="questions__accordion">
              <div class="accordion__title">{{ $t('faqs_accordion_q11_title') }}</div>
              <div class="accordion__description">{{ $t('faqs_accordion_q11_description') }}</div>
            </div>

            <div class="questions__accordion">
              <div class="accordion__title">{{ $t('faqs_accordion_q12_title') }}</div>
              <div class="accordion__description">{{ $t('faqs_accordion_q12_description') }}</div>
            </div>

            <div class="questions__accordion">
              <div class="accordion__title">{{ $t('faqs_accordion_q13_title') }}</div>
              <div class="accordion__description">{{ $t('faqs_accordion_q13_description') }}</div>
            </div>

          </div>
        </div>

      </div>

    </section>

  </div>
</template>

<script>
import Map from '@/components/Map.vue';

export default {
	name: "Content",
  name: 'VideoComponent',
  components: {
    Map
  },
	data() {
    return {
      services: [
        {
          icon_color: 'red',
          line_color: 'red'
        },
        {
          icon_color: 'yellow',
          line_color: 'yellow'
        },
        {
          icon_color: 'green',
          line_color: 'green'
        },
        {
          icon_color: 'red',
          line_color: 'red'
        },
      ]
    }
  },
  mounted() {

    /*const map = new Datamap({
      element: document.getElementById('usaMap'),
      scope: 'usa',
      fills: {
        defaultFill: '#E4E2E2'
      },
      geographyConfig: {
        highlightFillColor: '#FCE51A',
        highlightBorderColor: '#FFFFFF',
        highlightBorderWidth: 1,
        highlightBorderOpacity: 1,
        popupTemplate: function(geo, data) {
          return [`<div class="popupMapHover"><img class="popupMapHover__img" src="${require('@/assets/img/popuo-img.png')}" alt="Icon"><p class="popupMapHover__name">${geo.properties.name}</p></div>`].join('');
        },
        responsive: true
      }
    });*/

    const acordeon = document.querySelectorAll('.questions__accordion');

    acordeon.forEach(element => {
      element.addEventListener('click', () => {
        element.classList.toggle('activa');
      });
    });

  }
}


</script>

<style>

.about {
  margin: 50px 0;
}

.video-container video {
  width: 100%; /* Adjusts the video to take the full width of the container */
  margin-top: 20px; /* Top margin */
  margin-bottom: 20px; /* Bottom margin */
}

.about__title {
  text-align: center;
  background: linear-gradient(90deg, #ED5A50 20.1%, #FBC400 80.95%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3.5rem;
  margin: 20px 0;
  font-weight: 600;
}

.about__description {
  text-align: center;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
}

.about__services {
  margin: 50px 0;
  display: flex;
  flex-wrap: wrap;
}

.services__container {
  width: 50%;
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  height: 100%;
  min-height: 230px;
}

.services__box:first-child {
  width: 14%;
  display: flex;
  justify-content: center;
}

.services__box:last-child {
  width: 86%;
  padding: 0 20px 0 5px;
}

.services__img {
  width: 57px;
  height: 57px;
}

.services__line {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 4px;
}

.services__title {
  font-weight: 600;
}

.services__description {
  margin-top: 10px;
}





.whyUs {
  padding: 0 0 50px 0;
}

.whyUs__container {
  /*background-color: #f1f0f0;*/
  border-radius: 25px;
}

.whyUs__title {
  text-align: center;
  padding: 40px 0;
}

.whyUs__reasons {
  display: flex;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  height: 100%;
  min-height: 350px;
  padding-bottom: 60px;
}

.reasons__box {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #F8F8F8;
}

.reasons__box--mod01 {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  background-image: url('@/assets/img/whyus/whyusbg-1.svg');
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px;
}

.reasons__box--mod02 {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  background-image: url('@/assets/img/whyus/whyusbg-2.svg');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px;
}

.reasons__img {
  width: 100px;
  margin: 20px 0 10px 0;
}

.reasons__title {
  text-align: center;
  margin-bottom: 10px;
}

.reasons__description {
  text-align: center;
}






.destination__container {
  position: relative;
}

.destination__title {
  text-align: center;
  padding: 20px 0;
}

.destination__subtitle {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;
  margin-bottom: 20px;
}

.destination__map {
  width: 100%;
  margin: 80px auto 50px auto;
  max-width: 800px;
  height: 100%;
  min-height: 400px;
  position: relative;
}

#usaMap {
  position: relative;
  width: 100%;
  height: 400px;
}

.popupMapHover {
  padding: 10px;
  background-color: var(--ComplementaryColor03);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.17);
  border-radius: 5px;
  display: flex;
}

.popupMapHover__img {
  width: 22px;
  margin-right: 8px;
}

.popupMapHover__name {
  font-size: 1.4rem;
  font-weight: 400;
}

.map__subtitle {
  background: linear-gradient(90deg, #ED5A50 20.1%, #FBC400 80.95%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.2rem;
  width: 100%;
  max-width: 300px;
  position: absolute;
  bottom: -80px;
  right: 50px;
}

#destination__testimonials1 {
  top: 200px;
  left: -20px;
}

#destination__testimonials2 {
  top: 430px;
  left: -20px;
}

#destination__testimonials3 {
  top: 170px;
  right: -20px;
}

#destination__testimonials4 {
  top: 400px;
  right: -20px;
}

.destination__testimonials {
  width: 305px;
  padding: 20px;
  display: flex;
  border-radius: 30px;
  position: absolute;
}

.testimonials__box--mod1 {
  width: 30%;
}

.testimonials__box--mod2 {
  width: 70%;
}

.testimonials__img {
  width: 65px;
}

.testimonials__name {
  font-size: 1.2rem;
  font-weight: 600;
}

.testimonials__description {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5rem;
}
















.faqs__title {
  padding: 40px 0 20px 50px;
  width: 100%;
  max-width: 500px;
}

.faqs__questions {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.questions__box {
  width: 50%;
}

.questions__subBox {
  padding: 40px 40px 0px 40px;
}

.questions__title {
  margin-bottom: 15px;
}

.questions__description {
  text-align: left;
}

.questions__accordion {
  position: relative;
  margin: 20px 10px;
  padding: 15px 25px;
  border-radius: 15px 15px 15px 15px;
  border: 1px solid var(--ComplementaryColor02)
}

.accordion__title {
  position: relative;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.8rem;
  color: var(--SecondaryColor);
}

.accordion__title::before {
  font-family: "FontAwesome";
  content: '\f107';
  position: absolute;
  top: 52%;
  right: 0;
  font-size: 1.6rem;
  transform: translateY(-50%);
}

.accordion__description {
  position: relative;
  height: 0;
  text-align: justify;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}

.questions__accordion.activa .accordion__description {
  height: auto;
  padding-top: 10px;
}

.questions__accordion.activa .accordion__title::before {
  content: '\f104';
}

.questions__ready {
  color: var(--SecondaryColor);
  font-size: 2rem;
  font-weight: 400;
  padding-top: 20px;
  display: block;
}

.questions__ready--link {
  background: linear-gradient(90deg, #ED5A50 20.1%, #FBC400 80.95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}



@media only screen and (max-width: 768px) {

  .hero__subtitle {
    font-size: 1.4rem;
  }

  .hero {
    height: 200px;
  }

  .about__services {
    margin: 40px 0;
  }

  .about__title {
    font-size: 2.5rem;
  }

  .services__container {
    width: 100%;
    flex-direction: column;
    background-color: var(--ComplementaryColor02);
    border-radius: 30px;
  }

  .services__box {
    width: 100% !important;
    padding: 0;
  }

  .services__title {
    text-align: center;
  }

  .services__description {
    text-align: center;
  }

  .services__img {
    margin-bottom: 20px;
  }

  .services__line {
    display: none;
  }

  .whyUs__reasons {
    flex-direction: column;
  }

  .reasons__box {
    width: 100%;
  }

  .destination {
    display: none;
  }

  .faqs__title {
    padding: 0px;
    text-align: center;
  }

  .whyUs {
    padding: 0 0 40px 0;
  }

  .faqs__questions {
    flex-direction: column;
  }

  .questions__box {
    width: 100%;
  }

  .questions__subBox {
    padding: 40px 10px 0px 10px;
  }

  .questions__accordion {
    margin: 20px 0px;
  }

  .questions__ready {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .preFooter__box {
    flex-direction: column;
    padding: 10px !important;
  }

  .preFooter__subBox {
    width: 100% !important;
  }

  .preFooter__title {
    font-size: 2.5rem !important;
    text-align: center !important;
  }

  .preFooter__description {
    text-align: center !important;
  }

  .footer {
    padding: 0 10px;
  }

  .footer__container {
    flex-direction: column;
    padding: 20px 10px 30px 10px !important;
  }

  .footer__box {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .footer__box--mod3 {
    justify-content: center !important;
  }

  .footer__box--mod2 {
    margin: 15px 0px !important;
    padding: 0 20px !important;
  }

}

</style>