<template>
  <div class="wrapper">

    <p class="mainContainer__mainTitle mainContainer__mainTitle--mod01">{{ $t('completeTheFinalSteps') }}</p>

    <div class="navigation navigation--mod1">
      <router-link class="navigation__link navigation__link--active navigation__link--done"
        :to="`/checkout/${this.$route.params.id}`"><span><strong>1</strong></span>{{ $t('transport') }}</router-link>
      <router-link class="navigation__link navigation__link--active navigation__link--done"
        :to="`/checkout/${this.$route.params.id}/pickup`"><span><strong>2</strong></span>{{ $t('pickup')
        }}</router-link>
      <router-link class="navigation__link navigation__link--active navigation__link--done"
        :to="`/checkout/${this.$route.params.id}/delivery`"
        style="pointer-events: none !important;"><span><strong>3</strong></span>{{ $t('delivery') }}</router-link>
      <router-link class="navigation__link" to="/"><span><strong style="color:#ffffff;">5</strong></span>{{ $t('finish')
        }}</router-link>
    </div>


    <div class="mainContainer" style="margin-top:30px;">

      <div class="mainContainer__box mainContainer__box--mod04 mainContainer__box--mod03" style="padding:30px;">
        <p class="mainContainer__mainTitle mainContainer__mainTitle--mod02">{{ $t('vehicleDeliveryDetails') }}</p>

        <!-- Google Maps Autocomplete -->
        <div class="formContainer__inputBox formContainer__inputBox--custom01">
          <input id="autocomplete" type="text" placeholder="Enter your address" />
        </div>

        <div id="map" style="height: 400px; width: 100%;"></div>

        <button class="formContainer__submit formContainer__submit--custom01" style="margin-top:20px;"
          @click="goBack">{{ $t('back') }}</button>
        <button class="formContainer__submit formContainer__submit--custom01" style="margin-top:20px;"
          @click="sendFormData">{{ $t('next') }}</button>
      </div>

      <div class="mainContainer__box mainContainer__box--mod04 mainContainer__box--mod03">

        <div class="mainContainer__informationalBox mainContainer__informationalBox--mod01">

          <p class="infoSection__title infoSection__title--mod01">{{ $t('route_information') }}</p>

          <div class="informationalBox__infoSection informationalBox__infoSection--mod01">
            <div class="infoSection__container infoSection__container--33">
              <p class="infoSection__title">{{ $t('from') }}</p>
              <p class="infoSection__select" id="selectFrom"> {{ this.$store.state.routeData.fromAddress || '-' }} </p>
            </div>
            <div class="infoSection__container infoSection__container--33 infoSection__container--flex">
              <div class="infoSection__line"></div>
              <div class="infoSection__miles" id="distanceMiles"> {{ this.$store.state.routeData.distanceFromTo + ' MI'
                }} </div>
              <div class="infoSection__line"></div>
            </div>
            <div class="infoSection__container infoSection__container--33">
              <p class="infoSection__title infoSection__textAlignRight">{{ $t('to') }}</p>
              <p class="infoSection__select infoSection__textAlignRight" id="selectTo"> {{
                this.$store.state.routeData.toAddress || '-' }} </p>
            </div>
          </div>

          <div class="informationalBox__infoSection">
            <div class="infoSection__container">
              <p class="infoSection__title">{{ $t('pickupdate') }}</p>
              <p class="infoSection__select"> {{ this.$store.state.routeData.pickupDate || '-' }} </p>
            </div>
            <div class="infoSection__container">
              <p class="infoSection__title">{{ $t('how_many_veh') }}</p>
              <p class="infoSection__select"> {{ this.$store.state.routeData.numberOfVehicles || '-' }} </p>
            </div>
          </div>

          <div class="informationalBox__infoSection">
            <div class="infoSection__container">
              <p class="infoSection__title" v-if="$store.state.routeData.numberOfVehicles == 1">{{
                $t('transported_vehicule')}}</p>
              <p class="infoSection__title" v-if="$store.state.routeData.numberOfVehicles > 1">{{
                $t('transported_vehicules')}}</p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles == 1"> {{
                $store.state.vehicleData.vehicleType || '-' }} </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 1">1. {{
                $store.state.vehicleData.vehicleType || '-' }} </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 1">2. {{
                $store.state.vehicleData.vehicleType_2 || '-' }} </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 2">3. {{
                $store.state.vehicleData.vehicleType_3 || '-' }} </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 3">4. {{
                $store.state.vehicleData.vehicleType_4 || '-' }} </p>
            </div>
          </div>

          <div class="informationalBox__infoSection informationalBox__infoSection--mod02">

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles == 1">
              {{ $t('aditional_services') }}</p>
            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 1">
              {{ $t('aditional_first_veh') }}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive') }}</p>
                <p class="infoSection__select"> {{ $store.state.additionalData.vehicleCondition || '-' }} </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{
                  $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{
                  $store.state.additionalData.vehiclePounds || '-' }} LB</p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{
                  $t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{
                  $store.state.additionalData.vehicleType || '-' }} </p>
              </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 1">
              {{ $t('aditional_second_veh') }}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03"
              v-if="$store.state.routeData.numberOfVehicles > 1">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive') }}</p>
                <p class="infoSection__select"> {{ $store.state.additionalData.vehicleCondition_2 || '-' }} </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{
                  $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{
                  $store.state.additionalData.vehiclePounds_2 || '-' }} LB</p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{
                  $t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{
                  $store.state.additionalData.vehicleType_2 || '-' }} </p>
              </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 2">
              {{ $t('aditional_third_veh') }}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03"
              v-if="$store.state.routeData.numberOfVehicles > 2">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive') }}</p>
                <p class="infoSection__select"> {{ $store.state.additionalData.vehicleCondition_3 || '-' }} </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{
                  $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{
                  $store.state.additionalData.vehiclePounds_3 || '-' }} LB</p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{
                  $t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{
                  $store.state.additionalData.vehicleType_3 || '-' }} </p>
              </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 3">
              {{ $t('aditional_fourth_veh') }}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03"
              v-if="$store.state.routeData.numberOfVehicles > 3">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive') }}</p>
                <p class="infoSection__select"> {{ $store.state.additionalData.vehicleCondition_4 || '-' }} </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{
                  $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{
                  $store.state.additionalData.vehiclePounds_4 || '-' }} LB</p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{
                  $t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{
                  $store.state.additionalData.vehicleType_4 || '-' }} </p>
              </div>
            </div>

          </div>

          <div class="informationalBox__infoSection">
            <div class="infoSection__container">
              <p class="infoSection__title infoSection__title--mod4">{{ $t('discount_price') }}<br><span
                  style="text-decoration:none;font-size:2.5rem;">$ {{ $store.state.reservationType === 'extended' ?
                    $store.state.finalData.oneToTenDaysPrice : $store.state.reservationType === 'standard' ?
                      $store.state.finalData.oneToFiveDaysPrice : $store.state.reservationType === 'urgent' ?
                  $store.state.finalData.oneToThreeDaysPrice : ''}} </span></p>
              <p class="infoSection__select infoSection__title--mod5">{{ $t('note_due') }} </p>
            </div>
          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'Delivery',
  data() {
    return {
      city: '',
      state: '',
      zip: '',
      fullAddress: '',
      map: null,
      marker: null,
      deliveryStreetAddress: '',
      deliveryAptAddress: '',
    };
  },
  mounted() {
    if (!window.google) {
      let script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      window.initMap = this.initMap;
      document.head.appendChild(script);
    } else {
      this.initMap();
    }
  },
  methods: {
    initMap() {
      const autocomplete = new google.maps.places.Autocomplete(
        document.getElementById('autocomplete')
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const components = place.address_components;

        const cityObj = components.find(component => component.types.includes('locality'));
        const stateObj = components.find(component => component.types.includes('administrative_area_level_1'));
        const zipObj = components.find(component => component.types.includes('postal_code'));

        this.city = cityObj ? cityObj.long_name : '';
        this.state = stateObj ? stateObj.short_name : '';
        this.zip = zipObj ? zipObj.long_name : '';
        this.fullAddress = place.formatted_address;

        // Update the map with the new location
        this.setMap(place.geometry.location);
      });

      // Initialize the map with a default location
      this.setMap();
    },
    setMap(location) {
      const mapOptions = {
        center: location || { lat: 40.7128, lng: -74.0060 },  // Default to New York City
        zoom: 15,
        styles: [
          {
            "featureType": "poi",
            "stylers": [{ "visibility": "off" }]
          },
          {
            "featureType": "transit",
            "elementType": "labels.icon",
            "stylers": [{ "visibility": "off" }]
          },
          // Add more styling options here
        ]
      };

      if (!this.map) {
        this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
      } else {
        this.map.setCenter(location || { lat: 40.7128, lng: -74.0060 });  // Default to New York City
      }

      if (this.marker) {
        this.marker.setMap(null);
      }

      this.marker = new google.maps.Marker({
        position: location || { lat: 40.7128, lng: -74.0060 },  // Default to New York City
        map: this.map
      });
    },
    sendFormData() {
      // Set deliveryStreetAddress and deliveryAptAddress to fullAddress
      this.deliveryStreetAddress = this.fullAddress;
      this.deliveryAptAddress = this.fullAddress;

      this.$store.dispatch('SAVE_CHECKOUT_DELIVERY_ACTION', {
        deliveryStreetAddress: this.deliveryStreetAddress,
        deliveryAptAddress: this.deliveryAptAddress,
        city: this.city,
        state: this.state,
        zip: this.zip,
        fullAddress: this.fullAddress
      });

      const customerInfo = this.$store.state.customerInfo;
      fetch('https://ratesautotransport.com/api/add-deal', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(customerInfo)
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Error en la solicitud: ' + response.statusText);
          }
          return response.json();
        })
        .then(data => console.log('Éxito:', data))
        .catch(error => console.error('Error:', error));

      this.$router.push(`/checkout/${this.$route.params.id}/successful`);
    },
    goBack() {
      this.$router.push(`/checkout/${this.$route.params.id}/pickup`);
    }
  }
};

</script>


<style></style>