<template>
  <div class="wrapper">

    <p class="mainContainer__mainTitle mainContainer__mainTitle--mod01">{{ $t('complete_steps') }}</p>

    <div class="navigation navigation--mod1">
      <router-link class="navigation__link navigation__link--active navigation__link--done"
        :to="`/checkout/${this.$route.params.id}`"><span><strong>1</strong></span>{{ $t('transport') }}</router-link>
      <router-link class="navigation__link navigation__link--active navigation__link--done" to="/"
        style="pointer-events: none !important;"><span><strong>2</strong></span>{{ $t('pickup') }}</router-link>
      <router-link class="navigation__link navigation__link--active navigation__link--done"
        to="/"><span><strong>3</strong></span>{{ $t('delivery') }}</router-link>
      <router-link class="navigation__link" to="/"><span><strong style="color:#ffffff;">4</strong></span>{{
        $t('bookNow') }}</router-link>
      <router-link class="navigation__link" to="/"><span><strong style="color:#ffffff;">5</strong></span>{{ $t('finish')
        }}</router-link>
    </div>


    <div class="mainContainer" style="margin-top:30px;">

      <div class="mainContainer__box mainContainer__box--mod04 mainContainer__box--mod03" style="padding:30px;">

        <!-- <p class="mainContainer__mainTitle mainContainer__mainTitle--mod02">{{ $t('last_step')}}</p> -->
        <p style="margin-left:10px;margin-top:-10px;">{{ $t('credit_charge') }}</p>


        <button class="formContainer__submit formContainer__submit--custom01" style="margin-top:20px;"
          @click="goBack">{{ $t('back') }}</button>




      </div>

      <div class="mainContainer__box mainContainer__box--mod04 mainContainer__box--mod03">

        <div class="mainContainer__informationalBox mainContainer__informationalBox--mod01">

          <p class="infoSection__title infoSection__title--mod01">{{ $t('route_information') }}</p>

          <div class="informationalBox__infoSection informationalBox__infoSection--mod01">
            <div class="infoSection__container infoSection__container--33">
              <p class="infoSection__title">{{ $t('from') }}</p>
              <p class="infoSection__select" id="selectFrom"> {{ this.$store.state.routeData.fromAddress || '-' }} </p>
            </div>
            <div class="infoSection__container infoSection__container--33 infoSection__container--flex">
              <div class="infoSection__line"></div>
              <div class="infoSection__miles" id="distanceMiles"> {{ this.$store.state.routeData.distanceFromTo + ' MI'
                }} </div>
              <div class="infoSection__line"></div>
            </div>
            <div class="infoSection__container infoSection__container--33">
              <p class="infoSection__title infoSection__textAlignRight">{{ $t('to') }}</p>
              <p class="infoSection__select infoSection__textAlignRight" id="selectTo"> {{
                this.$store.state.routeData.toAddress || '-' }} </p>
            </div>
          </div>

          <div class="informationalBox__infoSection">
            <div class="infoSection__container">
              <p class="infoSection__title">{{ $t('pickupdate') }}</p>
              <p class="infoSection__select"> {{ this.$store.state.routeData.pickupDate || '-' }} </p>
            </div>
            <div class="infoSection__container">
              <p class="infoSection__title">{{ $t('how_many_veh') }}</p>
              <p class="infoSection__select"> {{ this.$store.state.routeData.numberOfVehicles || '-' }} </p>
            </div>
          </div>

          <div class="informationalBox__infoSection">
            <div class="infoSection__container">
              <p class="infoSection__title" v-if="$store.state.routeData.numberOfVehicles == 1">{{
                $t('transported_vehicule')}}</p>
              <p class="infoSection__title" v-if="$store.state.routeData.numberOfVehicles > 1">{{
                $t('transported_vehicules') }}</p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles == 1"> {{
                $store.state.vehicleData.vehicleType || '-' }} </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 1">1. {{
                $store.state.vehicleData.vehicleType || '-' }} </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 1">2. {{
                $store.state.vehicleData.vehicleType_2 || '-' }} </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 2">3. {{
                $store.state.vehicleData.vehicleType_3 || '-' }} </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 3">4. {{
                $store.state.vehicleData.vehicleType_4 || '-' }} </p>
            </div>
          </div>

          <div class="informationalBox__infoSection informationalBox__infoSection--mod02">

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles == 1">
              {{ $t('aditional_services') }}</p>
            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 1">
              {{ $t('aditional_first_veh') }}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive') }}</p>
                <p class="infoSection__select"> {{ $store.state.additionalData.vehicleCondition || '-' }} </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{
                  $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{
                  $store.state.additionalData.vehiclePounds || '-' }} LB</p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{
                  $t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{
                  $store.state.additionalData.vehicleType || '-' }} </p>
              </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 1">
              {{ $t('aditional_second_veh') }}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03"
              v-if="$store.state.routeData.numberOfVehicles > 1">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive') }}</p>
                <p class="infoSection__select"> {{ $store.state.additionalData.vehicleCondition_2 || '-' }} </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{
                  $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{
                  $store.state.additionalData.vehiclePounds_2 || '-' }} LB</p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{
                  $t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{
                  $store.state.additionalData.vehicleType_2 || '-' }} </p>
              </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 2">
              {{ $t('aditional_third_veh') }}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03"
              v-if="$store.state.routeData.numberOfVehicles > 2">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive') }}</p>
                <p class="infoSection__select"> {{ $store.state.additionalData.vehicleCondition_3 || '-' }} </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{
                  $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{
                  $store.state.additionalData.vehiclePounds_3 || '-' }} LB</p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{
                  $t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{
                  $store.state.additionalData.vehicleType_3 || '-' }} </p>
              </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 3">
              {{ $t('aditional_fourth_veh') }}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03"
              v-if="$store.state.routeData.numberOfVehicles > 3">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive') }}</p>
                <p class="infoSection__select"> {{ $store.state.additionalData.vehicleCondition_4 || '-' }} </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{
                  $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{
                  $store.state.additionalData.vehiclePounds_4 || '-' }} LB</p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{
                  $t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{
                  $store.state.additionalData.vehicleType_4 || '-' }} </p>
              </div>
            </div>

          </div>

          <div class="informationalBox__infoSection">
            <div class="infoSection__container">
              <p class="infoSection__title infoSection__title--mod4">{{ $t('discount_price') }}<br><span
                  style="text-decoration:none;font-size:2.5rem;">$ {{ $store.state.reservationType === 'extended' ?
                    $store.state.finalData.oneToTenDaysPrice : $store.state.reservationType === 'standard' ?
                      $store.state.finalData.oneToFiveDaysPrice : $store.state.reservationType === 'urgent' ?
                  $store.state.finalData.oneToThreeDaysPrice : ''}} </span></p>
              <p class="infoSection__select infoSection__title--mod5">{{ $t('note_due') }} </p>
            </div>
          </div>

        </div>

      </div>

    </div>

  </div>
</template>


<script>
export default {
  name: "PaypalButton",
  data() {
    return {
      totalAmount: 0 // Initialize from store or other component
    };
  },
  mounted() {
    

  }
};
</script>


<style>
.full-width-element {
  width: 100%;
}

.grid-elements {
  display: flex;
  justify-content: space-between;
}

.third-width-element {
  width: calc(33.3333% - 10px);
  /* 10px is the gap between each element */
}

#card-number-element,
#card-expiry-element,
#card-cvc-element,
#postal-code-element {
  border: 1px solid #333;
  /* Darker border */
  border-radius: 5px;
  /* Rounded corners */
  padding: 12px;
  /* Inner spacing */
  margin-right: 15px;
  /* Space between elements */
  font-size: 16px;
  /* Font size */
  line-height: 1;
  /* Line height */
}

#card-number-element {
  margin-bottom: 20px;
  /* Adjust this value as needed */
}

/* For the last element, you might not want the right margin */
#postal-code-element {
  margin-right: 0;
}

.card-number-wrapper {
  position: relative;
}

.card-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 24px;
  /* Adjust as needed */
  height: auto;
}
</style>