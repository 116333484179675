<template>
  <PopupCard />

  <div>

    <Hero />

    <section class="quote">
      <div class="quote__container wrapper">
        <h2 class="quote__title">{{$t('check_3_quotes')}}</h2>
        <Form/>
      </div>
    </section>

    <Content />

    <Banner />
  </div>


</template>

<script>
import Form from '@/components/Form.vue';
import Hero from '@/components/Hero.vue';
import Content from '@/components/Content.vue';
import Banner from '@/components/banner/Banner.vue';

export default {
  name: 'Home',
  components: {
    Form,
    Hero,
    Content,
    Banner
  }
};
</script>

<style>

.quote__container {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.quote__title {
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {

  .quote__title {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .quote__container {
    padding: 0px !important;
  }

}

</style>