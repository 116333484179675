import { createRouter, createWebHistory} from 'vue-router'
import store from '@/store';
import Home from '../views/Home.vue'
import Quote from '../views/Quote.vue'
import Vehicle from '../views/Quote/Vehicle.vue'
import Additional from '../views/Quote/Additional.vue'
import Finish from '../views/Quote/Finish.vue'
import Checkout from '../views/Checkout.vue'
import Transport from '../views/Checkout/Transport.vue'
import Pickup from '../views/Checkout/Pickup.vue'
import Delivery from '../views/Checkout/Delivery.vue'
import BookNow from '../views/Checkout/BookNow.vue'
import Successful from '../views/Checkout/Successful.vue'
import PageNotFound from '../views/PageNotFound.vue'
import CustomAdditional from '../views/Quote/CustomAdditional.vue'
import CustomFinish from '../views/Quote/CustomFinish.vue'
import AdminLogin from '../components/AdminLogin.vue'
import Admin from '../components/Admin.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  
  {
    path: '/quote',
    name: 'Quote',
    component: Quote,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'Vehicle',
        component: Vehicle
      },
      {
        path: 'additional',
        name: 'Additional',
        component: Additional
      },
      {
        path: 'custom-additional',
        name: 'CustomAdditional',
        component: CustomAdditional
      },
      {
        path: 'finish',
        name: 'Finish',
        component: Finish
      },
      {
        path: 'custom-finish',
        name: 'CustomFinish',
        component: CustomFinish,
        beforeEnter: (to, from, next) => {
          if (from.name === 'CustomAdditional') {
            next();
          } else {
            next({ name: 'Vehicle' });  // or wherever you want to redirect
          }
        }
      }
    ]
  },
  {
    path: '/checkout/:id',
    name: 'Checkout',
    props: true,
    component: Checkout,
    children: [
      {
        path: '',
        name: 'Transport',
        component: Transport
      },
      {
        path: 'booknow',
        name: 'BookNow',
        component: BookNow,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'pickup',
        name: 'Pickup',
        component: Pickup,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'delivery',
        name: 'Delivery',
        component: Delivery,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'successful',
        name: 'Successful',
        component: Successful,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/:PageNotFound",
    component: PageNotFound
  },
  {
    path: '/es32fsase3451234s',
    component: AdminLogin,
  },
  {
    path: '/admin',
    name: '74sde4tbvb',
    component: Admin,
    beforeEnter: (to, from, next) => {
      if (to.query.qasd41dscdsse === 'deasa2312sad5?SDaE') {
        next();
      } else {
        next('/es32fsase3451234s');
      }
    },
  },
  
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    }
    return savedPosition || { top: 180 };
  },
});

router.beforeEach((to, from, next) => {

  const storeRouteData = store.state.routeData;

  if (to.matched.some(record => record.meta.requiresAuth) && Object.keys(storeRouteData).length === 0) {
    next({name: "Home"});
  } else {
    next();
  }

});

router.afterEach((to, from) => {
  // This line tells Google Analytics to record a page view
  gtag('config', 'G-PX2GDP17YS', {
    'page_path': to.path,
  });
});

export default router
