import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from './i18n';
import VueGtag from 'vue-gtag';
import Hotjar from '@hotjar/browser';

// Import CSS
import './assets/css/style.css';
import './assets/css/main.css';

// Import Firebase
import './firebase/config';

const app = createApp(App);

// Configure Vue Gtag
app.use(VueGtag, {
  config: { id: 'AW-11388837677' },
});

// Add Bitrix24 Live Chat script
(function(w,d,u){
  var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/60000|0);
  var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
})(window,document,'https://cdn.bitrix24.com/b2514537/crm/site_button/loader_18_9j7h8o.js');


// Initialize Hotjar
const siteId = 5002487;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

app.use(i18n);
app.use(store);
app.use(router);
app.mount('#app');
