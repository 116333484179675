<template>
  <div class="wrapper">
    <div class="navigation navigation--mod1">
      <router-link class="navigation__link navigation__link--active navigation__link--done" to="/"
        style="pointer-events: none !important;"><span><strong>1</strong></span> {{ $t('successful.transport')
        }}</router-link>
      <router-link class="navigation__link navigation__link--active navigation__link--done" to="/"
        style="pointer-events: none !important;"><span><strong>2</strong></span> {{ $t('successful.delivery') }}
      </router-link>
      <router-link class="navigation__link navigation__link--active navigation__link--done" to="/"
        style="pointer-events: none !important;"><span><strong>3</strong></span> {{ $t('successful.pickup')
        }}</router-link>
      <router-link class="navigation__link navigation__link--active navigation__link--done" to="/"
        style="pointer-events: none !important;"><span><strong>4</strong></span> {{ $t('successful.finish')
        }}</router-link>
    </div>

    <p class="mainContainer__mainTitle mainContainer__mainTitle--mod01">{{ $t('successful.paymentMethodSaved') }}</p>
    <p class="mainContainer__mainTitle mainContainer__mainTitle--mod01 mainContainer__mainTitle--mod02"
      style="margin-bottom:250px">{{ $t('successful.beAware') }}</p>
  </div>
</template>


<script>
import axios from "axios";

export default {
  name: 'Successful',
  mounted() {
    this.track();

    const customerInfo = this.$store.state.customerInfo;

    const vehicleData = () => {
      let data = [
        {
          vehicle: this.$store.state.vehicleData.vehicleType,
          vehiclePounds: this.$store.state.additionalData.vehiclePounds,
        },
        {
          vehicle: this.$store.state.vehicleData.vehicleType_2,
          vehiclePounds: this.$store.state.additionalData.vehiclePounds_2,
        },
        {
          vehicle: this.$store.state.vehicleData.vehicleType_3,
          vehiclePounds: this.$store.state.additionalData.vehiclePounds_3,
        },
        {
          vehicle: this.$store.state.vehicleData.vehicleType_4,
          vehiclePounds: this.$store.state.additionalData.vehiclePounds_4,
        }
      ];
      let numberVehicles = this.$store.state.routeData.numberOfVehicles;
      if (!numberVehicles > 1) {
        return [];
      }
      let response = [];
      for (let i = 0; i < numberVehicles; i++) {
        response.push(data[i]);
      }
      return response;
    }

    const bookingData = {
      lang: this.$i18n.locale,
      to: customerInfo.email,
      clientName: `${customerInfo.name} ${customerInfo.lastName}`,
      phone: customerInfo.phone,
      vehicleData: vehicleData(),
    }

    axios.post("https://ratesautotransport.com/api/email/booking",
      bookingData
    ).then((message) => {
      if (message.status === 200) {
        console.log("Booking email sent:", message.data);
      }
    }).catch((error) => {
      console.error("Error sending booking email:", error);
    });

    fetch('https://ratesautotransport.com/api/clear-timer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(customerInfo)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Timer cleared and deal added:', data);
      })
      .catch(error => {
        console.error('Error clearing timer and adding deal:', error);
      });
  },
  methods: {
    track() {
      this.$gtag.event('conversion', { 'send_to': 'AW-11388837677/PhOTCIKOs-8ZEK2-z7Yq' });
    }
  }
}
</script>
