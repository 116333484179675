<template>
    <div class="wrapper">
  
      <p class="mainContainer__mainTitle mainContainer__mainTitle--mod01">{{ $t('mainContainer.mainTitle') }}</p>
  
      <div class="mainContainer">
  
        <div class="mainContainer__box mainContainer__box--mod01">
  
            <div class="navigation">
          <router-link class="navigation__link navigation__link--active navigation__link--done" to="/"><span><i class="ri-check-fill"></i></span> {{ $t('mainContainer.stepOne') }}</router-link>
          <router-link class="navigation__link navigation__link--active navigation__link--done" to="/quote"><span><i class="ri-check-fill"></i></span> {{ $t('mainContainer.stepTwo') }}</router-link>
          <router-link class="navigation__link" to="/"><span><i class="ri-map-pin-line"></i></span> {{ $t('mainContainer.stepThree') }}</router-link>
          <router-link class="navigation__link" to="/"> {{ $t('mainContainer.stepFour') }}</router-link>
        </div>

            <!-- More Data -->
            <div class="formContainer">
                     <p class="formContainer__infoText">{{ $t('customAdditional.special_quote')}}</p>     
                <div class="formContainer__box">
           
                    <div class="formContainer__inputBox">
                        <label for="firstName">{{ $t('name')}}</label>
                        <input type="text" id="firstName" v-model="customerInfo.name" :placeholder=" $t('name')" />
                    </div>
                
                    <div class="formContainer__inputBox">
                        <label for="lastName">{{ $t('lastName') }}</label>
                        <input type="text" id="lastName" v-model="customerInfo.lastName" :placeholder=" $t('lastName')" />
                    </div>

                    <div class="formContainer__inputBox">
                        <label for="phone">{{ $t('phone')}}</label>
                        <input type="text" name="phoneNumber" id="phone" placeholder="0 000 000 0000 000000000" v-model="phoneNumber" autocomplete="off" maxlength="15" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" required>
                    </div>
                    <div class="formContainer__inputBox">
                        <label for="email">Email</label>
                        <input type="email" name="email" id="email" placeholder="Enter your e-mail address" v-model="email" autocomplete="off" required>
                    </div>
                    <div class="formContainer__inputBox">
                        <label for="email">{{ $t('successful.pickup') }}</label>
                        <input type="text" name="fromAddress" id="fromAddress" :placeholder="$t('formContainer.fromAddressPlaceholder')" autocomplete="off" v-model="customerInfo.zipCodePickup">
                    </div>
                    <div class="formContainer__inputBox">
                        <label for="email">{{ $t('successful.delivery')}}</label>
                        <input type="text" name="toAddress" id="toAddress" :placeholder="$t('formContainer.toAddressPlaceholder')" autocomplete="off" v-model="customerInfo.zipCodeDelivery">
                    </div>
                </div>
                                
                <div class="formContainer__submitBox">
                    <button class="formContainer__submit" v-on:click="backAction">Back</button>
                    <button class="formContainer__submit" id="continueStep" v-on:click="submitCustomForm">Continue</button>
                </div>

            </div>


        
        </div>
        <div class="mainContainer__box mainContainer__box--mod02">

        <div class="mainContainer__informationalBox">

        <p class="infoSection__title infoSection__title--mod01">{{ $t('route_information') }}</p>

        <div class="informationalBox__infoSection informationalBox__infoSection--mod01">
            <div class="infoSection__container infoSection__container--33">
            <p class="infoSection__title">{{ $t('from') }}</p>
            <p class="infoSection__select" id="selectFrom"> {{ this.$store.state.routeData.fromAddress || '-' }} </p>
            </div>
            <div class="infoSection__container infoSection__container--33 infoSection__container--flex">
            <div class="infoSection__line"></div>
            <div class="infoSection__miles" id="distanceMiles"> {{ this.$store.state.routeData.distanceFromTo + ' MI' }} </div>
            <div class="infoSection__line"></div>
            </div>
            <div class="infoSection__container infoSection__container--33">
            <p class="infoSection__title infoSection__textAlignRight">{{ $t('to') }}</p>
            <p class="infoSection__select infoSection__textAlignRight" id="selectTo"> {{ this.$store.state.routeData.toAddress || '-' }} </p>
            </div>
        </div>

        <div class="informationalBox__infoSection">
            <div class="infoSection__container">
            <p class="infoSection__title">{{ $t('pickupdate')}}</p>
            <p class="infoSection__select"> {{ this.$store.state.routeData.pickupDate || '-' }} </p>
            </div>
            <div class="infoSection__container">
            <p class="infoSection__title">{{ $t('how_many_veh')}}</p>
            <p class="infoSection__select"> {{ this.$store.state.routeData.numberOfVehicles || '-' }} </p>
            </div>
        </div>

        <div class="informationalBox__infoSection">
            <div class="infoSection__container">
        <p class="infoSection__title" v-if="$store.state.routeData.numberOfVehicles == 1">{{ $t('transported_vehicule') }}</p>
        <p class="infoSection__title" v-if="$store.state.routeData.numberOfVehicles > 1">{{ $t('transported_vehicules') }}</p>
            <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles == 1"> {{ displayVehicleInfo }} </p>

            <!-- <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 1">1. {{ $store.state.vehicleData.vehicleType || '-' }} </p>
            <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 1">2. {{ $store.state.vehicleData.vehicleType_2 || '-' }} </p>
            <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 2">3. {{ $store.state.vehicleData.vehicleType_3 || '-' }} </p>
            <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 3">4. {{ $store.state.vehicleData.vehicleType_4 || '-' }} </p> -->
            </div>
        </div>

        <div class="informationalBox__infoSection informationalBox__infoSection--mod02">

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles == 1">{{ $t('aditional_services')}}</p>
            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 1">{{ $t('aditional_first_veh')}}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03">
            <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive')}}</p>
                <p class="infoSection__select"> {{ vehicleCondition || '-' }} </p>
            </div>
            <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{ $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{ vehiclePounds || '-' }} LB</p>
            </div>
            <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{$t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{ vehicleType || '-' }} </p>
            </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 1">{{ $t('aditional_second_veh')}}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03" v-if="$store.state.routeData.numberOfVehicles > 1">
            <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive')}}</p>
                <p class="infoSection__select"> {{ vehicleCondition_2 || '-' }} </p>
            </div>
            <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{ $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{ vehiclePounds_2 || '-' }} LB</p>
            </div>
            <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{$t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{ vehicleType_2 || '-' }} </p>
            </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 2">{{ $t('aditional_third_veh')}}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03" v-if="$store.state.routeData.numberOfVehicles > 2">
            <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive')}}</p>
                <p class="infoSection__select"> {{ vehicleCondition_3 || '-' }} </p>
            </div>
            <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{ $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{ vehiclePounds_3 || '-' }} LB</p>
            </div>
            <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{$t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{ vehicleType_3 || '-' }} </p>
            </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 3">{{ $t('aditional_fourth_veh')}}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03" v-if="$store.state.routeData.numberOfVehicles > 3">
            <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive')}}</p>
                <p class="infoSection__select"> {{ vehicleCondition_4 || '-' }} </p>
            </div>
            <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{ $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{ vehiclePounds_4 || '-' }} LB</p>
            </div>
            <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{$t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{ vehicleType_4 || '-' }} </p>
            </div>
            </div>

        </div>

        </div>

        </div>
      </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
  name: "CustomAdditional",
  data() {
    return {
      vehicleCondition: 'Operable',
      vehicleCondition_2: 'Operable',
      vehicleCondition_3: 'Operable',
      vehicleCondition_4: 'Operable',
      vehicleConditionCheck: true,
      vehiclePounds: '100',
      vehiclePounds_2: '100',
      vehiclePounds_3: '100',
      vehiclePounds_4: '100',
      vehiclePoundsCheck: true,
      vehicleType: 'Open',
      vehicleType_2: 'Open',
      vehicleType_3: 'Open',
      vehicleType_4: 'Open',
      vehicleTypeCheck: true,
      continueButtonCheck: true,
      fromAddress: '',
      fromAddressState: '',
      fromAddressCity: '',
      fromAddressCheck: false,
      toAddress: '',
      toAddressState: '',
      toAddressCity: '',
      toAddressCheck: false,
      pickupDate: '',
      pickupDateCheck: false,
      distanceFromTo: '',
      continueButtonCheck: false,
      milesResponseCheck: false,
      numberOfVehicles: 1,
      customerInfo: {
        title: 'RateAutoTransport',
        name: '',
        lastName: '',
        email: '',
        phone: '',
        zipCodePickup: '',
        zipCodeDelivery: '',
        source_id: 'RateAutoTransport',
        car: null
      }
    }
  },
  methods: {
    checkIfCanContinue() {
      if (this.vehicleConditionCheck === true && this.vehiclePoundsCheck === true && this.vehicleTypeCheck === true) {
        this.continueButtonCheck = true;
      }
    },
    backAction() {
      this.$router.push({ name: "Vehicle" });
    },
    submitCustomForm() {
      // Dispatch the customer info to your Vuex store or handle it however you like
      this.$store.dispatch('SAVE_CUSTOM_INFO_ACTION', this.customerInfo);

    document.querySelector('#continueStep').setAttribute("disabled","");

      // Navigate to the next step
      this.$router.replace({ name: "CustomFinish" });
    }
  },
  mounted() {
  const fromAddressAutocomplete = new google.maps.places.Autocomplete(
    document.getElementById("fromAddress"),
    {
      componentRestrictions: { country: "us" },
      types: ["postal_code", "locality", "administrative_area_level_1"]
    }
  );
  
  fromAddressAutocomplete.addListener('place_changed', () => {
    const place = fromAddressAutocomplete.getPlace();
    if (place && place.address_components) {
      this.customerInfo.zipCodePickup = place.formatted_address;
    }
  });

  const toAddressAutocomplete = new google.maps.places.Autocomplete(
    document.getElementById("toAddress"),
    {
      componentRestrictions: { country: "us" },
      types: ["postal_code", "locality", "administrative_area_level_1"]
    }
  );
  
  toAddressAutocomplete.addListener('place_changed', () => {
    const place = toAddressAutocomplete.getPlace();
    if (place && place.address_components) {
      this.customerInfo.zipCodeDelivery = place.formatted_address;
    }
  });
},
  computed: {
    displayVehicleInfo() {
        const vehicleData = this.$store.state.vehicleData;
        this.customerInfo.car = this.$store.state.vehicleData;
            if (vehicleData.manualEntry) {
                return vehicleData.manualEntry;
            } else if (vehicleData.selectedBrand && vehicleData.selectedModel && vehicleData.selectedYear) {
                return `${vehicleData.selectedBrand} ${vehicleData.selectedModel} ${vehicleData.selectedYear}`;
            } else {
                return '-';
            }

        }
    }

}
</script>